<template>
  <div class="m1">
    <div class="m1-h">
      <h2>招聘信息列表</h2>
      <div class="m1-btns">
        <el-input v-model="form.key_id" style="width:169px; margin-right:20px" placeholder="请输入信息ID"></el-input>
        <el-input v-model="form.user_key_id" style="width:169px; margin-right:20px" placeholder="请输入用户ID"></el-input>
        <el-select @change="changeSubType(form.type_id)" v-model="form.type_id" style="width:169px; margin-right:20px" placeholder="请选择工种">
          <el-option v-for="item in tree" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <el-select v-model="form.sub_type_id" placeholder="请选择" style="width:169px; margin-right:20px">
          <el-option v-for="item in subTree" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <el-select @change="changeCity(form.province_id)" v-model="form.province_id" style="width:169px; margin-right:20px" placeholder="请选择省份">
          <el-option v-for="item in areaLists" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-select v-model="form.city_id" style="width:169px; margin-right:20px" placeholder="请选择城市">
          <el-option v-for="item in citys" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-select v-model="form.token_type" style="width:169px; margin-right:20px" placeholder="请选择抓取平台">
          <el-option label="工地干活啦" value="1"></el-option>
          <el-option label="职蜂" value="2"></el-option>
          <el-option label="明天兼职" value="3"></el-option>
          <el-option label="大象找活" value="4"></el-option>
          <el-option label="鱼泡快招" value="5"></el-option>
        </el-select>
        <div style="margin-top: 20px;">
          <el-input v-model="form.phone" style="width:169px; margin-right:20px" placeholder="请输入联系电话"></el-input>
          <span style="margin-right:20px">
            发布时间 
            <el-date-picker
              v-model="dates"
              type="daterange"
              style="margin-left:10px;"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
        </span>
        <span style="margin-right:20px">
            审核时间 
            <el-date-picker
              v-model="dates2"
              type="daterange"
              style="margin-left:10px;"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
        </span>
        <el-input v-model="form.token_name" style="width:169px; margin-right:20px" placeholder="请输入抓取账号"></el-input>
        </div>
        <div style="margin-top:20px">
          电话获取次数
          <el-select v-model="form.phone_count" style="width:169px; margin-right:20px; margin-left:10px;" placeholder="请选择次数">
            <el-option label="全部" value=""></el-option>
            <el-option label="0次" value="1"></el-option>
            <el-option label="1-5" value="2"></el-option>
            <el-option label="6-10" value="3"></el-option>
            <el-option label="10次以上" value="4"></el-option>
          </el-select>
          审核状态
          <el-select v-model="form.audit_status" style="width:169px; margin-right:20px; margin-left:10px;" placeholder="请选择次数">
            <el-option label="全部" value=""></el-option>
            <el-option label="待审核" value="0"></el-option>
            <el-option label="已通过" value="1"></el-option>
            <el-option label="已拒绝" value="2"></el-option>
          </el-select>
          <el-button type="primary" @click="getList()">查询</el-button>
          <el-button  @click="toAdd()" plain type="primary">添加</el-button>
          <el-upload
            style="display:inline-block; margin: 0 10px"
            class="upload-demo"
            action="http://api.dreamzhankai.com/resource/uploads"
            :headers="{token: token}"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-success="handleSuccess"
            :before-remove="beforeRemove"
            name="files"
            multiple
            :on-exceed="handleExceed"
            :show-file-list="false">
            <el-button plain type="primary">导入</el-button>
          </el-upload>
          
          <el-button @click="toDeletes()" plain type="primary">批量删除</el-button>
          <el-button @click="reset()" plain type="primary">重置</el-button>
          <el-button @click="exportExcel()" plain type="primary">导出</el-button>
        </div>
      </div>
    </div>
    <el-table
      border
      :data="data.data"
      @selection-change="handleSelectionChange"
      style="width: 100%">
      <el-table-column
      type="selection"
      width="55">
    </el-table-column>
      <el-table-column
        prop="key_id"
        label="信息ID"
        width="180">
        <template slot-scope="scope">
          <a @click="toDetail(scope.row.id)" href="javascript:;">{{scope.row.key_id}}</a>
        </template>
      </el-table-column>
      <el-table-column
        prop="user_key_id"
        label="发布用户ID"
        width="180">
      </el-table-column>
      <el-table-column
        prop="type_name"
        label="一级工种">
      </el-table-column>
      <el-table-column
        prop="sub_type_name"
        label="二级工种">
      </el-table-column>
      <el-table-column
        prop="province_name"
        label="省">
      </el-table-column>
      <el-table-column
        prop="city_name"
        label="城市">
      </el-table-column>
      <el-table-column
        prop="content"
        label="正文">
        <template slot-scope="scope">
          <div v-html="scope.row.content"></div> 
        </template>
      </el-table-column>
      <el-table-column
        prop="publish_time"
        label="发布时间">
      </el-table-column>
      <el-table-column
        prop="audit_time"
        label="审核时间">
      </el-table-column>
      <el-table-column
        prop="audit_name"
        label="审核人">
      </el-table-column>
      <el-table-column
        prop="audit_status_name"
        label="审核状态">
      </el-table-column>
      <el-table-column
        prop="phone"
        label="联系电话">
      </el-table-column>
      <el-table-column
        prop="phone_count"
        label="电话被获取次数">
      </el-table-column>
    </el-table>
    
    <div class="m1-r1">
      <div>共<span class="m1-text1">{{data.total}}</span>条记录</div>
      <div>
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="getList()"
          :current-page.sync="form.page"
          :total="data.total">
        </el-pagination>
      </div>
    </div>

    <el-dialog
      title="添加信息"
      :visible.sync="dialogVisible"
      width="50%">
      <el-form ref="form" :model="addForm" label-width="80px">
        <el-form-item label="工种">
          <el-select @change="changeSubType(addForm.type_id)" v-model="addForm.type_id" placeholder="请选择" style="margin-right:14px">
            <el-option v-for="item in tree" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-select v-model="addForm.sub_type_id" placeholder="请选择">
            <el-option v-for="item in subTree" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="地区">
          <el-select @change="changeCity(addForm.province_id)" v-model="addForm.province_id" placeholder="请选择省份" style="margin-right:14px">
            <el-option v-for="item in areaLists" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-select v-model="addForm.city_id" placeholder="请选择城市">
            <el-option v-for="item in citys" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户ID">
          <el-input v-model="addForm.user_key_id" placeholder="不填默认系统ID"></el-input>
        </el-form-item>
        <el-form-item label="正文">
          <el-input v-model="addForm.content" placeholder="请输入正文"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="addForm.phone" placeholder="请输入手机号"></el-input>
        </el-form-item>
        
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toSave()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  list, detail, save, tree, deletes, imports, areaLists
} from '@/api/employment';
import moment from 'moment'
export default {
  name: 'index',
  data() {
    return {
      dialogVisible: false,
      dialogVisible2: false,
      form: {
        key_id: '',
        user_key_id: '',
        type_id: '',
        sub_type_id: '',
        province_id: '',
        city_id: '',
        phone: '',
        publish_begin_time: '',
        publish_end_time: '',
        audit_begin_time: '',
        audit_end_time: '',
        phone_count: '',
        audit_status: '',
        token_type: '',
        token_name: '',
        page: 1,
        page_size: 10,
      },
      addForm: {
        user_key_id: '',
        type_id: '',
        sub_type_id: '',
        province_id: '',
        city_id: '',
        phone: '',
        content: ''
      },
      dates: [],
      dates2: [],
      data: {},
      tree: [],
      subTree: [],
      areaLists: [],
      multipleSelection: [],
      citys: [],
      token: ''
    }
  },
  computed: {
  },
  created() {
    
  },
  mounted(){
    this.token = localStorage.getItem('token')
    this.getList()
    this.getTree()
    this.getAreaLists()
  },
  methods: {
    getList () {
      console.log(this.dates, this.dates2)
      this.form.publish_begin_time = this.dates && this.dates.length > 0 ? moment(this.dates[0]).format('YYYY-MM-DD') : '';
      this.form.publish_end_time = this.dates && this.dates.length > 0 ? moment(this.dates[1]).format('YYYY-MM-DD') : '';
      this.form.audit_begin_time = this.dates2 && this.dates2.length > 0 ? moment(this.dates2[0]).format('YYYY-MM-DD') : '';
      this.form.audit_end_time = this.dates2 && this.dates2.length > 0 ? moment(this.dates2[1]).format('YYYY-MM-DD') : '';
      this.form.is_export = undefined
      this.form.token = undefined
      list(this.form).then(res =>{
        this.data = res.data.data
        this.data.data.forEach((item) => {
          if (item.content.indexOf(item.type_keyword) != -1) {
            item.content = item.content.replace(item.type_keyword, `<strong style="color:#409EFF">${item.type_keyword}</strong>`)
          }
        })
      })
    },
    exportExcel () {
      this.form.publish_begin_time = this.dates && this.dates.length > 0 ? moment(this.dates[0]).format('YYYY-MM-DD') : '';
      this.form.publish_end_time = this.dates && this.dates.length > 0 ? moment(this.dates[1]).format('YYYY-MM-DD') : '';
      this.form.audit_begin_time = this.dates2 && this.dates2.length > 0 ? moment(this.dates2[0]).format('YYYY-MM-DD') : '';
      this.form.audit_end_time = this.dates2 && this.dates2.length > 0 ? moment(this.dates2[1]).format('YYYY-MM-DD') : '';
      this.form.token = localStorage.getItem('token')
      this.form.is_export = 1
      list(this.form).then(res =>{
        location.href = res.data.data
      })
    },
    getTree () {
      tree().then(res =>{
        this.tree = res.data.data
      })
    },
    getAreaLists () {
      areaLists().then(res =>{
        this.areaLists = res.data.data
      })
    },
    toDetail (id) {
      window.open('employment/detail?id=' + id)
    },
    toSave () {
      save(this.addForm).then(res =>{
        if (res.data.msg == 'ok') {
          this.$message({
            type: 'success',
            message: '保存成功'
          });
          this.dialogVisible = false;
          this.getList()
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg
          });   
        }
      })
    },
    toImports (id) {
      imports({
        resource_id: id
      }).then(res =>{
        if (res.data.msg == 'ok') {
          this.$message({
            type: 'success',
            message: '导入成功'
          });
          this.getList()
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg
          });   
        }
      })
    },
    toDeletes () {
      let ids = []
      this.multipleSelection.forEach((item) => {
        ids.push(item.id)
      })
      this.$confirm('是否确认删除', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deletes({
          ids: ids
        }).then(res =>{
          this.getList()
        })
      }).catch(() => {
                  
      });
    },
    toAdd () {
      this.dialogVisible = true;
      this.addForm = {
        user_key_id: '',
        type_id: '',
        sub_type_id: '',
        province_id: '',
        city_id: '',
        phone: '',
        content: ''
      }
      this.subTree = []
      this.citys = []
    },
    changeSubType (id) {
      this.addForm.sub_type_id = ''
      this.form.sub_type_id = ''
      this.tree.forEach((item) => {
        if (item.id == id) {
          this.subTree = item.children
        }
      })
    },
    changeCity (id) {
      this.addForm.city_id = ''
      this.form.city_id = ''
      this.areaLists.forEach((item) => {
        if (item.value == id) {
          this.citys = item.children
        } 
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(val)
    },
    reset () {
      this.form = {
        key_id: '',
        user_key_id: '',
        type_id: '',
        province_id: '',
        city_id: '',
        phone: '',
        publish_begin_time: '',
        publish_end_time: '',
        audit_begin_time: '',
        audit_end_time: '',
        phone_count: '',
        page: 1,
        page_size: 10
      }
      this.dates = []
      this.dates2 = []
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    },
    handleSuccess (res) {
      console.log(res)
      this.toImports(res.id)
    }
  },
}
</script>
<style scoped lang="scss">
.m1-h{
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.m1-r1{
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.m1-text1{
  color: #409EFF;
}
.m1-btns{
  flex: 1;
  margin-left: 87px;
}
</style>
